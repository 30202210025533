import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgArrowTopRight = (props) => (
  <SvgIcon {...props} title="ArrowTopRight">
    <svg
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.607 25.607 24.82 4.393m0 0H3.607m21.213 0v21.214"
        stroke="#017163"
        strokeWidth={7}
        strokeLinecap="round"
      />
    </svg>
  </SvgIcon>
);

export default SvgArrowTopRight;
