import React from 'react';
import {
  CardActionArea,
  CardActionAreaProps,
  Box,
  Grid,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { lightTheme } from '../../theme/index';
import ArrowBottomLeft from '../../icons/ArrowBottomLeft';
import ArrowTopRight from '../../icons/ArrowTopRight';
import BarVertical from '../../icons/BarVertical';
import getTitleStyles from './utility';
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';

export interface LibCardPersonaProps extends CardActionAreaProps {
  title?: string;
  richTextTitle?: string;
  description?: string;
  richTextSubtitle?: string;
  statValue: string;
  link?: string;
  linkText?: string;
  variant?: 'base' | 'myFoxtons';
}

const { palette } = lightTheme;

function LibCardPersona({
  title,
  richTextTitle,
  description,
  richTextSubtitle,
  statValue,
  link,
  linkText,
  variant = 'base'
}: LibCardPersonaProps) {
  const overrides = {
    ...markdownOverrides,
    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        sx: getTitleStyles(variant),
        className: 'HeadingTitleClass'
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3',
        sx: getTitleStyles(variant),
        className: 'HeadingTitleClass'
      }
    },
    h4: {
      component: Typography,
      props: {
        variant: 'h4',
        sx: getTitleStyles(variant),
        className: 'HeadingTitleClass'
      }
    },
    p: {
      component: Typography,
      props: {
        variant: 'caption',
        sx: styles.description,
        className: 'DescTextHoverClass'
      }
    },
    span: {
      component: Typography,
      props: {
        variant: 'caption',
        sx: styles.description
      }
    }
  };

  const descriptionModified = `${statValue} ${richTextSubtitle}`;

  return (
    <Card sx={styles.baseState}>
      <CardActionArea
        href={link}
        sx={styles.cardActionContainer}
        className="cardActionContainer"
      >
        <Grid container sx={styles.mainContainer}>
          <Grid item xs={12}>
            <CardContent sx={styles['.MuiCardContent-root']}>
              <Box
                sx={styles.internalContainer}
                className="InternalContainerClass"
              >
                <Box sx={styles.topIconContainer}>
                  <ArrowBottomLeft
                    sx={styles.topIconYellow}
                    className="TopIconYellow"
                  />
                  <ArrowTopRight
                    sx={styles.topIconGreen}
                    className="TopIconGreen"
                  />
                </Box>
                {/* Remove this title post migration activity */}
                {title && (
                  <Typography
                    variant="body1"
                    sx={getTitleStyles(variant)}
                    className="HeadingTitleClass"
                  >
                    {title}
                  </Typography>
                )}
                {/* ----------------------------------------- */}
                {richTextTitle ? (
                  <Markdown options={{ overrides }}>{richTextTitle}</Markdown>
                ) : null}

                <Box sx={styles.footerMobile}>
                  <BarVertical
                    style={{
                      fill: palette.primary.main,
                      fontSize: '1rem'
                    }}
                  />
                  <Typography variant="button" sx={styles.linkText}>
                    {linkText}
                  </Typography>
                </Box>

                <Box sx={styles.descTextHover} className="DescTextHoverClass">
                  <Typography variant="caption">
                    {descriptionModified}
                  </Typography>
                </Box>
                <Box sx={styles.footerStyle} className="FooterStyleClass">
                  <Typography variant="h2" sx={styles.statsNo}>
                    {statValue}
                  </Typography>
                  {/* Remove this title post migration activity */}
                  {description && (
                    <Typography variant="body1" sx={styles.description}>
                      {description}
                    </Typography>
                  )}
                  {/* ----------------------------------------- */}
                  {richTextSubtitle ? (
                    <Markdown options={{ overrides }}>
                      {richTextSubtitle}
                    </Markdown>
                  ) : null}
                </Box>
                <Box
                  sx={styles.footerMobileHover}
                  className="footerMobileHover"
                >
                  <BarVertical
                    style={{
                      fill: palette.secondary.main,
                      fontSize: '1.5rem'
                    }}
                  />
                  <Typography variant="button" sx={styles.linkTextBottomHover}>
                    {linkText}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

export const CardPersona = React.memo(LibCardPersona);

export default CardPersona;
