import React from 'react';
import { Box, Grid, Typography } from '@mui/material/';
import Markdown from 'markdown-to-jsx';
import { CardPersona } from '../../components/CardPersona';
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';

export interface LibPersonaCardsSectionProps {
  richTitle?: string;
  cardData?: Array<{
    title?: string;
    richTextTitle?: string;
    description?: string;
    richTextSubtitle?: string;
    statValue: string;
    link?: string;
    linkText?: string;
    variant?: 'base' | 'myFoxtons';
  }>;
}

function LibPersonaCardsSection({
  richTitle,
  cardData = []
}: LibPersonaCardsSectionProps) {
  const overrides = {
    ...markdownOverrides,
    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        sx: styles.headingTitle
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3',
        sx: styles.headingTitle
      }
    }
  };

  return (
    <Box sx={styles.container}>
      <Markdown options={{ overrides }}>{richTitle}</Markdown>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {cardData.map((card) => (
          <Grid item xs={12} md={6} lg={3} key={card.title}>
            <CardPersona
              richTextTitle={card?.richTextTitle}
              // description={card?.richTextSubtitle}
              richTextSubtitle={card?.richTextSubtitle}
              statValue={card?.statValue}
              linkText={card?.linkText}
              link={card?.link}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export const PersonaCardsSection = React.memo(LibPersonaCardsSection);

export default PersonaCardsSection;
