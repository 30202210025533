import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, breakpoints, spacing } = lightTheme;

const titleHeading = {
  textAlign: 'left',
  color: palette.primary.main,
  margin: '0',
  wordBreak: {
    md: 'break-word',
    lg: 'normal'
  },
  padding: {
    xs: '0 1.5rem 1.25rem 1.25rem',
    md: '0 1rem 1.25rem 1rem',
    lg: '0 1.875rem 1.25rem 1.875rem',
    xl: '0 1.875rem 1.25rem 1.875rem'
  }
};

const styles: Record<string, SxProps> = {
  baseState: {
    borderRadius: '0',
    transition: 'transform 0.5s',
    backgroundColor: palette.grey['100'],
    zIndex: '0',
    boxShadow: 'none',
    height: '12rem',
    width: '100%',

    [breakpoints.up('md')]: {
      height: '100%',

      '&:hover': {
        transform: 'scale(1.05)',
        transformOrigin: 'center',
        boxShadow: 'rgb(0 0 0 / 22%) 0 1.25rem 1.25rem 0.3125rem',
        height: '100%',
        transition: 'transform 0.5s',
        borderRadius: '0',
        background:
          'radial-gradient(36.47% 24.41% at 100% 66.04%, rgba(0, 205, 179, 0.4) 0%, rgba(0, 231, 202, 0.1898) 48.89%, rgba(0, 255, 223, 0) 93.04%);',
        zIndex: '12',

        '&:hover .cardActionContainer': {
          height: '100%',
          minHeight: {
            xs: '17rem',
            sm: '12rem',
            md: '17rem',
            mm: '20rem',
            lg: '20rem',
            xl: '22rem'
          }
        },

        '&:hover .MuiCardContent-root': {
          height: '100%',
          minHeight: {
            xs: '17rem',
            sm: '12rem',
            md: '17rem',
            mm: '20rem',
            lg: '20rem',
            xl: '22rem'
          },
          color: palette.grey['50'],
          padding: '0',
          background:
            'linear-gradient(180deg, rgba(1, 113, 99, 0) 59.05%, #017163 100%), #017163'
        },
        '&:hover .HeadingTitleClass': {
          color: palette.secondary.main,
          margin: '0',
          padding: {
            xs: '0 1.5rem 1.25rem 1.25rem',
            md: '0 1rem 1.25rem 1rem',
            lg: '0 1.875rem 1.25rem 1.875rem',
            xl: '0 1.875rem 1.25rem 1.875rem'
          },
          wordWrap: 'break-world'
        },
        '&:hover .InternalContainerClass': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '0.3125rem',
          height: '100%'
        },
        '&:hover .TopIconYellow': {
          display: 'flex'
        },
        '&:hover .TopIconGreen': {
          display: 'none'
        },
        '&:hover .FooterStyleClass': {
          display: 'none'
        },
        '&:hover .DescTextHoverClass': {
          display: {
            xs: 'none',
            md: 'flex'
          },
          marginTop: 'auto',
          marginBottom: spacing(1)
        },
        '&:hover .footerMobileHover': {
          display: {
            xs: 'none',
            md: 'flex'
          },
          marginTop: 'auto',
          marginBottom: '2rem',
          paddingLeft: spacing(1)
        }
      }
    }
  },

  cardActionContainer: {
    height: '100%'
  },

  mainContainer: {
    height: '100%'
  },

  '.MuiCardContent-root': {
    color: palette.primary.main,
    padding: '0',
    '&:last-child': {
      paddingBottom: '0.75rem'
    },
    height: '100%'
  },

  headingTitle: {
    ...titleHeading
  },

  internalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    gap: {
      xs: '0',
      md: '0.3125rem'
    }
  },

  topIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    WebkitJustifyContent: 'flex-end',
    alignItems: 'center',
    padding: {
      xs: '0.3125rem',
      md: spacing(1)
    },
    paddingBottom: {
      md: spacing(0.5)
    }
  },
  topIconGreen: {
    fontSize: {
      xs: '1.0rem',
      md: '1.5rem'
    }
  },
  topIconYellow: {
    display: 'none',
    fontSize: {
      xs: '1.0rem',
      md: '1.5rem'
    }
  },

  descTextHover: {
    display: 'none',
    color: palette.grey['50'],
    padding: '0 2.5rem 0 1.875rem'
  },

  footerMobile: {
    display: {
      xs: 'flex',
      md: 'none'
    },
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.3125rem',
    padding: '0.9375rem 1.25rem 1rem 0.875rem',
    marginTop: 'auto'
  },
  linkText: {
    color: palette.grey.main,
    marginBottom: '0'
  },

  footerStyle: {
    display: {
      xs: 'none',
      md: 'flex'
    },
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: {
      xs: '1.25rem 2.5rem 0.625rem 1.875rem',
      md: '0.75rem',
      lg: '1.25rem 2.5rem 0.625rem 1.875rem'
    },
    gap: {
      xs: '1.875rem',
      md: '0.5rem',
      mm: '0.75rem',
      lg: '1.25rem',
      xl: '1.875rem'
    },
    marginTop: 'auto',
    minHeight: '6.625rem'
  },
  footerMobileHover: {
    display: 'none'
  },
  statsNo: {
    color: palette.primary.main
  },
  description: {
    color: palette.grey.main
  },
  linkTextBottomHover: {
    lineHeight: '1.5rem',
    fontWeight: '700',
    color: palette.secondary.main
  }
};

export default styles;
