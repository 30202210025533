import { SxProps } from '@mui/material';
import styles from './styles';

const getTitleStyles = (variant: 'base' | 'myFoxtons') => {
  const titleStyle: Record<string, SxProps> = {
    base: {
      width: {
        xs: '100%',
        xl: '18rem'
      }
    },
    myFoxtons: {
      width: {
        xs: '100%',
        md: '15.75rem',
        xl: '18.75rem'
      }
    }
  };

  return {
    ...styles.headingTitle,
    ...titleStyle[variant]
  };
};

export default getTitleStyles;
