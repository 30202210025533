import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgArrowBottomLeft = (props) => (
  <SvgIcon {...props} title="ArrowBottomLeft">
    <svg
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.82 4.393 3.605 25.607m0 0h21.213m-21.213 0V4.393"
        stroke="#fff000"
        strokeWidth={7}
        strokeLinecap="round"
      />
    </svg>
  </SvgIcon>
);

export default SvgArrowBottomLeft;
